<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Sequence <span>Schedules</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="send-now-filter" />
                                </li>
                                <li></li>
                                <li class="list_info">
                                    {{ schedules.from ? schedules.from : 0 }} - {{ schedules.to ? schedules.to : 0 }} of <span>{{ schedules.total ? schedules.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th>Schedule Time</th>
                                    <th>Timezone</th>
                                    <th>User Names</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(schedule, s) of schedules.data" :key="s">
                                    <td>{{ (moment.tz(schedule.schedule_utc, 'UTC')).tz(schedule.time_zone).format('ll LT') }}</td>
                                    <td>{{ schedule.time_zone }}</td>
                                    <td>{{ schedule.contact_names }}</td>
                                    <td>
                                        <ul class="action_list">
                                            <li @click="handleDeleteSchedule(schedule.id)"><i class="fas fa-trash-alt"></i></li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr v-if="schedules.total == 0">
                                    <td colspan="4">No records found!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination pb-4 mt-4">
                        <pagination v-model="params.page" :pages="schedules.last_page" @update:modelValue="handlePagination" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment-timezone'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Sequence Send Now',

        data () {
            return {
                selectedUsers: [],
                params: {
                    per_page: 5,
                    page: 1,
                },
                moment,
            };
        },

        props: {
            modelValue: Boolean,
            sequence: Object,
            message: Object,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.params = {
                        per_page: 5,
                        page: 1,
                        sequence_id: vm.sequence.id,
                        message_id: vm.message.id,
                    };

                    vm.getSequenceMessageSchedule(vm.params);
                }
            },
        },

        computed: mapState({
            schedules: state => state.sequenceModule.schedules,
            scheduleLoader: state => state.sequenceModule.scheduleLoader,
        }),

        methods: {
            ...mapActions({
                getSequenceMessageSchedule: 'sequenceModule/getSequenceMessageSchedule',
                deleteSequenceSchedule: 'sequenceModule/deleteSequenceSchedule',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getSequenceMessageSchedule(vm.params);
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['send-now-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const emailFilter = vm.$refs['send-now-filter'];

                if (emailFilter) {
                    emailFilter.dropdown = false;
                }
            },

            handleDeleteSchedule (id) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this schedule.`, 'Delete');

                options.preConfirm = function () {
                                        return vm.deleteSequenceSchedule(id).then((result) => {
                                            if (result) {
                                                vm.getSequenceMessageSchedule(vm.params);
                                                Toastr.success(result.message);
                                            }
                                        });
                                    };

                Swal.fire(options);
            },
        },
    }
</script>

<style scoped>
    .result_wpr table td:first-child,
    .result_wpr table th:first-child {
        min-width: 100px;
    }

    .action_list li i.fa-trash-alt{
        color: #eb1414;
    }
</style>
